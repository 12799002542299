// api.js

import Axios from 'axios';
import Swal from 'sweetalert2';
import Servidor from 'util/Servidor';
import Storage from 'util/store/Storage'

export const login = async (datos, actualizarEstado, handleLoader) => {

  Axios.post(Servidor.host + Servidor.puertoApp + '/api/v1/signin/', datos)
    .then(response => {
      if (response.status === 200) {
        sessionStorage.removeItem('token')
        sessionStorage.setItem('token', response.data.data.token)
        sessionStorage.setItem('ip', datos?.accessLogs?.ipAccess||'access ip no allowed')
        sessionStorage.setItem('currentUser', JSON.stringify(response.data.data))
        setTimeout(() => {
          actualizarEstado(true, response.data.data)
        }, 200)
        if (response.data.data.expired_pass) {
          Swal.fire(
            'Actualización de contraseña',
            '¡Se ha actualizado tu contraseña de manera automática por tu seguridad! <br /> Por favor revisar su correo electrónico',
            'question'
          )
        return
        }
      } else if (response.status === 401) {
        alert(response.data.message);
        handleLoader();
      } else {
        alert('¡CREDENCIALES INCORRECTAS!');
        handleLoader();
      }
    })
    .catch(err => {
      console.log(err);
      Swal.fire('Advertencia',err.response.data.message || 'ERROR TECNICO', 'warning');
      handleLoader();
    });
};

export const forgetPassword = async (data, headers, handleDialogOlvideContrasena) => {
  let mensaje = ''

  Axios.put(Servidor.host + Servidor.puertoCentury + '/api/persona/forgetPassword', data, {
    headers: headers
  }).then(response => {
    let mensaje = response.data.mensaje

    if (response.status === 200 && response.data.success) {
      Swal.fire({
        title: mensaje,
        icon: 'success',
        closeOnClickOutside: false,
        buttons: {
          aceptar: {
            text: 'OK',
            value: 'continuar'
          }
        }
      }).then(value => {
        if (value == 'continuar') {
          Storage.dispatch({
            type: 'OLVIDO_CONTRASEÑA',
            ventanaOlvideContra: false
          })
          handleDialogOlvideContrasena()
        }
      })
    } else {

      Swal.fire('ERROR', mensaje, 'warning')
    }
  }).catch((reason)=>{
    mensaje = reason?.response?.data?.mensaje || 'Error al resetear contraseña'
    Swal.fire('ERROR', mensaje, 'warning')

  })
}
