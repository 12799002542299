import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoaderTerpel from 'components/LoaderTerpel/LoaderTerpel'


//rutas
const Vehiculos = lazy(async () => import('./Vehiculos'))
const Flotas = lazy(async () => import('./Flotas'))
const Clientes = lazy(async ()=> import('./Clientes'))
const Restricciones = lazy(async ()=> import('./Restricciones'))

const ClienteCredito = ({ match }:any) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<LoaderTerpel />}>
          <Route path={`${match.url}/flotas`} component={Flotas} />
          <Route path={`${match.url}/vehiculos`} component={Vehiculos} />
          <Route path={`${match.url}/clientes`} component={Clientes} />
          <Route path={`${match.url}/restricciones`} component={Restricciones} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default ClienteCredito
