import axios from "axios";
import server from 'util/Servidor'


export const getCountriesServices = async () => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + '/api/v1/staticValue/country', { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('Error: ', error);
        return []
    }
}

export const getStateByCountryServices = async (country: number) => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + `/api/v1/staticValue/state/${country}`, { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('Error: ', error);
        return []
    }
}

export const getCityByStateServices = async (state: number) => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + `/api/v1/staticValue/city/${state}`, { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('Error: ', error);
        return []
    }
}

export const getBusinessService = async () => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + '/api/v1/staticValue/businessType', { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}
export const getCompanyTypeService = async () => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + '/api/v1/staticValue/companyType', { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}

export const getStateService = async () => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + '/api/v1/staticValue/status', { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}

export const getRegionalService = async (business:number) => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + `/api/v1/regionalBusiness/regionalGroup/${business}`, { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}

export const getEDSByIDService = async (id_company:number) => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + `/api/v1/company/${id_company}`, { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}

export const getIdentificationTypes = async () => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + `/api/v1/staticValue/identificationType`, { headers: { Authorization: server.obtenerToken() } })
        return response.data.result
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}

export const getProveedoresTecnologicos = async () => {
    try {
        const response = await axios.get(server.host + server.puertoCentury + `/api/empresa/proveedores-tecnologicos`, { headers: { Authorization: server.obtenerToken() } })
        return response.data.data
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}

export const getOrganizationsService = async () => {
    try {
        const response = await axios.get(server.host + server.puerto_integracion_sap + `/api/v1/organization/organizationListing`, { headers: { Authorization: server.obtenerToken() } })
        return response.data
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}


