import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Typography, Modal, App } from 'antd';
import Servidor from 'util/Servidor'
import backgroundImage from 'assets/images/login/background_fw_genexis_mx.png';
import logoPng from 'assets/images/login/logo_genexis.png';
import IconUser from 'assets/images/login/IconUser.svg';
import IconPasswordOn from 'assets/images/login/IconPasswordOn.svg';
import IconPasswordOff from 'assets/images/login/IconPasswordOff.svg';
import { login, forgetPassword } from './helper';
import Storage from 'util/store/Storage';
import server from 'util/Server'
import moment from 'moment';
import './Login.css';
import useGeolocation from 'hooks/useGeolocation';
import useIPAddress from 'hooks/useIPAddress';
import { detectOS } from 'hooks/useDetectOS';

const { Text } = Typography;

declare global {
  interface Window {
    grecaptcha: any;
    onRecaptchaLoad: () => void;
  }
}

const checkUser = () => {
  const { dominio, tipo_empresa, negocio } = Storage.getState()
  return tipo_empresa === 3 ? negocio : dominio
}

const SignIn: React.FC = () => {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [state, setState] = useState({
    username: '',
    password: '',
    passwordVisible: false,
    forgotModalVisible: false,
    forgotUsername: '',
    forgotCedula: '',
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // Added state for captcha verification

  const deviceType = detectOS()
  const { location } = useGeolocation()
  const { ip } = useIPAddress()
  const { notification } = App.useApp()


  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Cargar el script de reCAPTCHA
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.onRecaptchaLoad = () => {
        if (recaptchaRef.current) {
          window.grecaptcha.render(recaptchaRef.current, {
            sitekey: server.captchapKey,
            callback: (token: string) => {
              setCaptchaToken(token);
              setIsCaptchaVerified(true); // Update captcha verification state
              notification.success({ message: 'reCAPTCHA verificado', description: 'El captcha ha sido completado.' });
            },
            'expired-callback': () => {
              setCaptchaToken(null);
              setIsCaptchaVerified(false); // Update captcha verification state
              notification.warning({ message: 'reCAPTCHA expirado', description: 'Por favor, complete el captcha nuevamente.' });
            },
            size: 'normal',
            theme: 'dark',
          });
        }
      };

      if (window.grecaptcha && window.grecaptcha.render) {
        window.onRecaptchaLoad();
      }
    };

    return () => {
      /* @ts-ignore */
      delete window.onRecaptchaLoad;
    };
  }, [notification]);

  const togglePasswordVisibility = () => {
    setState(prevState => ({ ...prevState, passwordVisible: !prevState.passwordVisible }));
  };

  const handleChange = (field: string, value: string) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const validateFields = () => {
    if (state.username === '') {
      notification.warning({ message: 'Advertencia', description: 'El campo usuario es requerido' });
      return false;
    }
    if (state.password === '') {
      notification.warning({ message: 'Advertencia', description: 'El campo contraseña es requerido' });
      return false;
    }
    if (!captchaToken) {
      notification.warning({ message: 'Advertencia', description: 'Por favor, complete el reCAPTCHA' });
      return false;
    }
    return true;
  };


  const resetCaptcha = () => {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
      setCaptchaToken(null);
      setIsCaptchaVerified(false);
    }
  };

  const iniciarSesion = async () => {
    if (!validateFields()) return;

    const datos = {
      user: state.username.toUpperCase(),
      pass: state.password,
      identificadorNegocio: checkUser(),
      captcha: captchaToken,
      accessLogs: {
        ipAccess: ip,
        latitude: location.latitude,
        longitude: location.longitude,
        origin: deviceType
      },
    };

    try {
      const handleLoginSuccess = (token: string, user: any) => {
        Storage.dispatch({
          type: 'INICIO_DE_SESION',
          token,
          usuario: user,
          obtencionInfoUsuario: true
        });
        window.location.reload();
      };

      await login(datos, handleLoginSuccess, resetCaptcha);
    } catch (error) {
      console.error('Error en el inicio de sesión:', error);
      notification.error({ message: 'Error', description: 'Hubo un problema al iniciar sesión. Por favor, intente nuevamente.' });
      resetCaptcha();
    }
  };

  const enviarDatosOlvide = async () => {
    if (!state.forgotCedula || !state.forgotUsername) {
      notification.warning({ message: 'Advertencia', description: 'Todos los campos son necesarios' });
      return;
    }

    if (state.forgotCedula && state.forgotCedula.length > 20) return notification.warning({
      message: 'Atención',
      description: 'Campo Cedula permite máximo 20 caracteres'
    })
    if (state.forgotUsername && state.forgotUsername.length > 20) return notification.warning({
      message: 'Atención',
      description: 'Campo Cedula permite máximo 20 caracteres'
    })

    const data = {
      identificacion: state.forgotCedula,
      usuario: state.forgotUsername.toUpperCase(),
      dominio: Storage.getState().dominio,
    };

    const headers = {
      Authorization: Servidor.obtenerToken(),
      versionapp: Servidor.versionApp,
      versioncode: Servidor.versionCode,
      fecha: moment().format(),
      aplicacion: Servidor.aplicacion,
      identificadordispositivo: Servidor.aplicacion
    }

    await forgetPassword(data, headers, () => {
      notification.success({ message: 'Éxito', description: 'Solicitud enviada correctamente' });
    });

    setState(prevState => ({ ...prevState, forgotModalVisible: false, forgotCedula: '', forgotUsername: '' }));
  };

  return (
    <div className='login-container'>
      <div className='login-background' style={{
        background: `url(${backgroundImage}) no-repeat`,
        backgroundPositionX: 'center',
      }}>
        <div className='login-content'>
          <div className='logo-wrapper'>
            <img src={logoPng} alt="Logo Genexis" className='logo' />
          </div>
          <Text className='login-title'>Iniciar sesión</Text>

          <Form onFinish={iniciarSesion}>
            <Form.Item
              name="username"
              label={isMobile ? <span id="user-prefix" className="prefix-text">Usuario</span> : null}
              labelCol={isMobile ? { span: 24, style: { textAlign: 'center' } } : undefined}
            >
              <Input
                id="usernameLogin"
                size="large"
                placeholder="Ingrese su usuario"
                value={state.username}
                onChange={e => handleChange('username', e.target.value)}
                prefix={!isMobile ? <div className="input-prefix"><span className="prefix-text">Usuario</span></div> : null}
                suffix={<img src={IconUser} alt="User Icon" className="input-icon-user" />}
                rootClassName="user-input"
              />
            </Form.Item>

            <Form.Item
              name="password"
              label={isMobile ? <span className="prefix-text">Contraseña</span> : null}
              labelCol={isMobile ? { span: 24, style: { textAlign: 'center' } } : undefined}
            >
              <Input
                id='passwordLogin'
                size="large"
                type={state.passwordVisible ? "text" : "password"}
                placeholder="Ingrese su contraseña"
                value={state.password}
                onChange={e => handleChange('password', e.target.value)}
                prefix={!isMobile ? <div className="input-prefix"><span className="prefix-text">Contraseña</span></div> : null}
                suffix={
                  <img
                    src={state.passwordVisible ? IconPasswordOn : IconPasswordOff}
                    alt="Toggle Password Visibility"
                    className={state.passwordVisible ? "input-icon-password" : "input-icon-password off"}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: 'pointer' }}
                  />
                }
                rootClassName="password-input"
              />
            </Form.Item>

            <div className="recaptcha-container" style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px 0',
              opacity: isCaptchaVerified ? 0.5 : 1, // Added opacity for visual disabling
              pointerEvents: isCaptchaVerified ? 'none' : 'auto' // Added pointerEvents for disabling interaction
            }}>
              <div ref={recaptchaRef}></div>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" className='login-button'>Entrar</Button>
            </Form.Item>
            <Form.Item>
              <Text className='forgot-password'>
                <a onClick={() => setState(prevState => ({ ...prevState, forgotModalVisible: true }))}>
                  Olvidé mi contraseña
                </a>
              </Text>
            </Form.Item>
          </Form>
          <Text className='version-text'>V.1.0.0.0</Text>
        </div>
      </div>

      <Modal
        title="Olvidé mi Contraseña"
        open={state.forgotModalVisible}
        onCancel={() => setState(prevState => ({ ...prevState, forgotModalVisible: false }))}
        footer={[
          <Button key="back" onClick={() => setState(prevState => ({ ...prevState, forgotModalVisible: false }))}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={enviarDatosOlvide}>
            Enviar
          </Button>,
        ]}
      >
        <Input
          placeholder="Escriba su identificación"
          value={state.forgotCedula}
          onChange={e => handleChange('forgotCedula', e.target.value)}
        />
        <Input
          placeholder="Escriba su usuario"
          value={state.forgotUsername}
          onChange={e => handleChange('forgotUsername', e.target.value)}
          style={{ marginTop: '10px' }}
        />
      </Modal>
    </div>
  );
};

export default SignIn;
