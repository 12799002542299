import LoaderTerpel from 'components/LoaderTerpel/LoaderTerpel'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Regionales from './Regionales'

//rutas
// const AcuerdosClientes = lazy(async () => import('./AcuerdosClientes'))
// const AcuerdosEmpresas = lazy(async () => import('./AcuerdosEmpresas'))
const AsignacionParametros = lazy(async () => import('./AsignacionParametros'))
const Auditoria = lazy(async () => import('./Auditoria'))
const ClientesPrepagos = lazy(async () => import('./ClientesPrepagos'))
const Conversiones = lazy(async () => import('./Conversiones'))
const Descuentos = lazy(async () => import('./GestionDescuentos'))
const Geografia = lazy(async () => import('./Geografia'))
const GestionAcuerdos = lazy(async () => import('./GestionAcuerdos/index'))
const GestionAforo = lazy(async () => import('./GestionAforo'))
const GestionBodega = lazy(async () => import('./GestionBodegas'))
const GestionBonos = lazy(async () => import('./GestionBonos'))
const GestionCalibraciones = lazy(async () => import('./GestionCalibraciones'))
const GestionCampania = lazy(async () => import('./GestionCampania'))
const GestionCanastillas = lazy(async () => import('./GestionCanastillas'))
const GestionCartera = lazy(async () => import('./GestionCartera'))
const GestionCategorias = lazy(async () => import('./GestionCategorias'))
const GestionCierres = lazy(async () => import('./GestionCierres'))
const GestionDescuentos = lazy(async () => import('./GestionDescuentos'))
const GestionDescuentosVehiculos = lazy(async () => import('./GestionDescuentos/DescuentosVehiculo'))
const GestionFacturas = lazy(async () => import('./GestionFacturas'))
const GestionFinanciera = lazy(async () => import('./GestionFinanciera'))
const GestionIdentificadores = lazy(async () => import('./GestionIdentificadores'))
const GestionImpuestos = lazy(async () => import('./GestionImpuestos'))
const GestionInventarioCombustible = lazy(async () => import('./GestionInventarioCombustible'))
const GestionInventarioProductos = lazy(async () => import('./InventarioProductos'))
const GestionMediosPagos = lazy(async () => import('./GestionMediosPagos'))
const GestionMetas = lazy(async () => import('./GestionMetas'))
const GestionPerfiles = lazy(async () => import('./GestionPerfiles'))
const GestionPlanillas = lazy(async () => import('./GestionPlanillas'))
const GestionProductos = lazy(async () => import('./GestionProductos'))
const GestionProveedores = lazy(async () => import('./GestionProveedores/index'))
const GestionRecibos = lazy(async () => import('./GestionRecibos'))
const GestionResoluciones = lazy(async () => import('./GestionResoluciones'))
const GestionRestricciones = lazy(async () => import('./GestionRestricciones'))
const GestionSobres = lazy(async () => import('./GestionSobres'))
const GestionTipoVehiculo = lazy(async () => import('./GestionTipoVehiculo/index'))
const GestionTurno = lazy(async () => import('./GestionTurno'))
const GestionUnidades = lazy(async () => import('./GestionUnidades'))
const GestionVerificadores = lazy(async () => import('./GestionVerificadores'))
const Globales = lazy(async () => import('./Globales'))
const Grupos = lazy(async () => import('./Grupos'))
const MedicionTanques = lazy(async () => import('./MedicionTanques'))
const Modulos = lazy(async () => import('./Modulos/Modulos'))
const Parametros = lazy(async () => import('./Parametros/Parametros'))
//en typeScript
const CambioPrecios = lazy(async () => import('./CambioPrecios/index'))
const Equipos = lazy(async () => import('./Equipos'))
const ConceptoInventario = lazy(async () => import('./GestionConceptosInventario'))
const ConfiguracionVentas = lazy(async () => import('./ConfiguracionVentas'))
const GestionDatafonos = lazy(async () => import('./GestionDatafonos'))
const RegistroCliente = lazy(async ()=>import('./RegistroCliente'))
const SincronizacionResoluciones = lazy(async ()=>import('./SincronizacionResoluciones'))
const Organizaciones = lazy(async ()=>import('./Organizaciones'))

const Administracion = lazy(async () => import('./Administracion'))

const Configuracion = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<LoaderTerpel />}>
          {/* <Route path={`${match.url}/acuerdos_clientes`} component={AcuerdosClientes} /> */}
          {/* <Route path={`${match.url}/acuerdos_eds`} component={AcuerdosEmpresas} /> */}
          <Route path={`${match.url}/asignacion-parametros-equipos`} component={AsignacionParametros} />
          <Route path={`${match.url}/auditoria`} component={Auditoria} />
          <Route path={`${match.url}/cambios_precios_eds`} component={CambioPrecios} />
          <Route path={`${match.url}/conversiones`} component={Conversiones} />
          <Route path={`${match.url}/descuentos_vehiculos`} component={GestionDescuentosVehiculos} />
          <Route path={`${match.url}/descuentos`} component={Descuentos} />
          <Route path={`${match.url}/equipos`} component={Equipos} />
          <Route path={`${match.url}/geografia`} component={Geografia} />
          <Route path={`${match.url}/gestion_acuerdos`} component={GestionAcuerdos} />
          <Route path={`${match.url}/gestion_aforo`} component={GestionAforo} />
          <Route path={`${match.url}/gestion_bodega`} component={GestionBodega} />
          <Route path={`${match.url}/gestion_bonos`} component={GestionBonos} />
          <Route path={`${match.url}/gestion_calibraciones`} component={GestionCalibraciones} />
          <Route path={`${match.url}/gestion_campañas`} component={GestionCampania} />
          <Route path={`${match.url}/gestion_canastillas`} component={GestionCanastillas} />
          <Route path={`${match.url}/gestion_cartera`} component={GestionCartera} />
          <Route path={`${match.url}/gestion_categorias`} component={GestionCategorias} />
          <Route path={`${match.url}/gestion_cierres_automaticos`} component={GestionCierres} />
          <Route path={`${match.url}/gestion_clientes_prepagos`} component={ClientesPrepagos} />
          <Route path={`${match.url}/gestion_descuentos`} component={GestionDescuentos} />
          <Route path={`${match.url}/gestion_facturas`} component={GestionFacturas} />
          <Route path={`${match.url}/gestion_financieras`} component={GestionFinanciera} />
          <Route path={`${match.url}/gestion_impuestos`} component={GestionImpuestos} />
          <Route path={`${match.url}/gestion_inventario_combustible`} component={GestionInventarioCombustible} />
          <Route path={`${match.url}/gestion_inventario_productos`} component={GestionInventarioProductos} />
          <Route path={`${match.url}/gestion_medios_identificacion`} component={GestionIdentificadores} />
          <Route path={`${match.url}/gestion_medios_pagos`} component={GestionMediosPagos} />
          <Route path={`${match.url}/gestion_metas`} component={GestionMetas} />
          <Route path={`${match.url}/gestion_perfiles`} component={GestionPerfiles} />
          <Route path={`${match.url}/gestion_planilla_promotores`} component={GestionPlanillas} />
          <Route path={`${match.url}/gestion_proveedores`} component={GestionProveedores} />
          <Route path={`${match.url}/gestion_recibos`} component={GestionRecibos} />
          <Route path={`${match.url}/gestion_resoluciones`} component={GestionResoluciones} />
          <Route path={`${match.url}/gestion_restricciones_cf`} component={GestionRestricciones} />
          <Route path={`${match.url}/gestion_sobres`} component={GestionSobres} />
          <Route path={`${match.url}/gestion_tipo_vehiculo`} component={GestionTipoVehiculo} />
          <Route path={`${match.url}/gestion_turnos`} component={GestionTurno} />
          <Route path={`${match.url}/gestion_unidades`} component={GestionUnidades} />
          <Route path={`${match.url}/gestion_verificadores`} component={GestionVerificadores} />
          <Route path={`${match.url}/globales`} component={Globales} />
          <Route path={`${match.url}/grupos`} component={Grupos} />
          <Route path={`${match.url}/medicion_tanques`} component={MedicionTanques} />
          <Route path={`${match.url}/modulos`} component={Modulos} />
          <Route path={`${match.url}/parametros`} component={Parametros} />
          <Route path={`${match.url}/productos`} component={GestionProductos} />
          <Route path={`${match.url}/conceptos-inventario`} component={ConceptoInventario} />
          <Route path={`${match.url}/configuracion_ventas`} component={ConfiguracionVentas} />
          <Route path={`${match.url}/gestion_datafonos`} component={GestionDatafonos} />
          <Route path={`${match.url}/regionales`} component={Regionales} />
          <Route path={`${match.url}/registro_clientes`} component={RegistroCliente} />
          <Route path={`${match.url}/organizaciones`} component={Organizaciones} />

        </Suspense>
      </Switch>
    </div>
  )
}

export default Configuracion
